@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body .container2{
    display: flex;
    justify-content: space-around;
    margin-top: 150px;
}

body .container2 .card {
  position: relative;
   min-width: 350px; 
  height: 350px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

body .container2 .card:nth-child(1) .box .content a {
  background: #0a0a0a;
}

body .container2 .card:nth-child(2) .box .content a {
  background: #ce1a22;
}

body .container2 .card:nth-child(3) .box .content a {
  background: #0a0a0a;
}

body .container2 .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

body .container2 .card .box:hover {
  transform: translateY(-50px);
}

body .container2 .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

body .container2 .card .box .content {
  padding: 20px;
  text-align: center;
}

body .container2 .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

body .container2 .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

body .container2 .card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

body .container2 .card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
body .container2 .card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}
.dorik-section-ab2jbjkk {
    width: 100%;
    position: relative;
    background-color: #ce1a22;
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 50px 50px 50px 50px;
    margin-top: -100px;
    margin-bottom: auto;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.dorik-row-p5qzo5wr {
  --nav-padding: 15px;
}
@media (max-width: 768px) {
  .dorik-row-p5qzo5wr {
     flex-direction: column;
  }
  
}
.dorik-column-sx477vmg {
  padding-top: 15px;
  padding-bottom: 15px;
}
.column-inner {
  width: 100%;
  position: relative;
}
.dorik-wrapper {
  display: block;
  position: relative;
}
.dorik-heading-u7q2i2rj.dorik-heading-u7q2i2rj.dorik-heading-u7q2i2rj {
  margin-bottom: 0px;
  margin-top: 0px;
}
.flex {
  display: flex
;
}
.dorik-text-3q3j7rrw {
  text-align: left;
}
.dorik-text {
  margin-top: 1em;
  margin-bottom: 1em;
}
@media screen and (min-width: 1024px) {
  .col-lg-2\/5 {
      width: 40%;
  }
}
@media screen and (min-width: 1024px) {
  .col-lg-3\/5 {
      width: 60%;
  }
}
.dorik-column-n4vsrfs2 {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgba(0, 0, 0, 0);
}
.dorik-accordion-exws77h5 details {
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #000;
  background-color: var(--color-3);
  overflow: hidden;
  height: 48px;
  transition: height 0.3s ease-in-out;
  margin-bottom: 15px;
}

.dorik-accordion-exws77h5 details[open] {
  height: 193px;
  animation: accordionOpen 0.4s ease-in-out;
}

.dorik-accordion-exws77h5 details:not([open]) {
  height: 48px;
  animation: accordionClose 0.4s ease-in-out;
}

.dorik-accordion-exws77h5 details[open] .details-content {
  animation: slideDown 0.6s ease-out;
  height: 145px; /* 193px - 48px (header height) */
  overflow-y: scroll;
}

.dorik-accordion-exws77h5 details:not([open]) .details-content {
  animation: slideUp 0.6s ease-out;
  height: 0;
}

.dorik-accordion-exws77h5 details[open] summary::before {
  content: '-';
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.dorik-accordion-exws77h5 details:not([open]) summary::before {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.dorik-accordion-exws77h5 summary {
  background-color: var(--color-2);
  font-size: 18px;
  line-height: 2;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  user-select: none;
}

.dorik-accordion-exws77h5 summary:hover {
  background-color: #b0161d;
}

.dorik-accordion-exws77h5 summary::before {
  padding-left: 10px;
  font-size: inherit;
  content: '+';
  float: right;
  transition: transform 0.3s ease;
}

.dorik-accordion-exws77h5 .details-content {
  max-height: 0;
  opacity: 0;
  transition: all 0.6s ease-out;
}

.dorik-accordion-exws77h5 details[open] .details-content {
  max-height: 1000px;
  opacity: 1;
}

@keyframes accordionOpen {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

@keyframes accordionClose {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-15px);
    max-height: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    max-height: 1000px;
  }
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
    max-height: 1000px;
  }
  100% {
    opacity: 0;
    transform: translateY(-15px);
    max-height: 0;
  }
}



.dorik-accordion-exws77h5 .details-content p {
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--color-4);
}
@media (max-width: 768px) {
  .dorik-accordion-exws77h5 summary {
    background-color: var(--color-2);
    font-size: 10px;
    line-height: 2;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    user-select: none;
  }
  .dorik-accordion-exws77h5 .details-content p {
    font-size: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--color-4);
  }
}
.dorik-button-05lyzjtb {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  justify-content: center;
  border-radius: 50px 50px 50px 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #000;
  background-color: rgba(0, 0, 0, 0);
  font-size: 18px;
  color: var(--color-3);
  line-height: 18px;
}
.dorik-btn {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
}
#leftrightsectionistek{
  padding: 30px;
}
@media screen and (max-width: 767px) {
  .dorik-section-ab2jbjkk {
      border-radius: 20px 20px 20px 20px;
  }
}