@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Rubik+Doodle+Triangles&display=swap');
p{
    text-align: justify;
    font-weight: lighter;
}
.section-title{
    margin-bottom: 10%!important;
}

/*NAVBAR*/
.navbar-toggler{
    background-color: #e3e3e3!important;

}
.navbar-toggler-icon{
    width: 1.2rem;
    height: 1.2rem;
}

#navbarCollapse{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.5rem;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 991px) {
    .carousel .slider-wrapper {
        width: 75%!important;
    }
}
@media (max-width: 991px) {
    #navbarCollapse{
        height: 1rem;
        background-color: rgba(255, 255, 255, 0.9)
    }
}
@media screen and (max-width: 530px){
    #navbarCollapse{
        height: 3rem;

    }
}
@media screen and (min-width: 530px) and (max-width: 991px) {
    /* Bu alana 530px ile 991px arasında uygulanacak CSS kodlarını ekleyin*/
    #navbarCollapse{
        background-color: rgba(255, 255, 255, 0.5);
    }
    #navbarTablet{
        margin-top: -7%;
    }
    #language{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    #navbarContentTextRoute{
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        white-space: nowrap; 
    }
    #responsive-navbar-nav{
        margin-top: -3%;
        font-size: x-large;
    }
    #mainLogoClass{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

}
#navbarBar{
    display: flex;
    justify-content: center;
    color: #000;
    font-weight: bold;
    white-space: nowrap;
}

#navbarBar div {
    white-space: nowrap;
}

#navbarBar div a {
   /* transition: transform 0.3s ease-in-out;*/
    color: #000 !important;
    display: inline-block;
    white-space: nowrap;
}

/*#navbarBar div a:hover {
    transform: scale(1.2);
}*/

#firstChild:hover,
#secondChild:hover,
#thirdChild:hover,
#fourthChild:hover {
    /*font-size: larger;*/
}

#firstChild a,
#secondChild a,
#thirdChild a,
#fourthChild a,
#fifthChild a {
    position: relative;
    transition: transform 0.3s ease;
    white-space: nowrap;
    display: inline-block;
}

#firstChild a:hover,
#secondChild a:hover,
#thirdChild a:hover,
#fourthChild a:hover,
#fifthChild a:hover {
    transform: scale(1.2);
}

#navbarContentTextRoute{
    color: #000;
    font-weight: bold;
}

.logoImage{
width: 30%;
}
@media (max-width: 991px) {
    #responsive-navbar-nav {
        background-color: rgba(255, 255, 255, 0.9);
        height: 1000%;
    }
    #navbarBar div a {
        color: #000 !important;
    }
}
#language button{
    background-color: transparent;
    width: 7%;
}
#language button img{
    width: 20px!important;
}

/*HOME*/
.awardsBox{
    width:175px;
    height:190px;
    background-color:white;
    border-radius:105px 105px 15px 15px;
    box-shadow: 10px 2px 30px 3px white;
    position: absolute;
    right: 0;
    color: #0b0f08;
}
@media (max-width: 800px) {
    .awardsBox{
        position: absolute;
        /*
        width:100%;
        */
        height:100px;
        border-radius:50px 50px 14px 14px;
        font-size: xx-small;
        margin-top: 290px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*
        margin-right: 105px;
        */
    }

}
.awardsBoxBody{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    font-style:italic;
    text-align: center;
    margin-top: 10px;
}
#counterCont{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: baseline;

}
@media (max-width: 500px) {
    #counterCont{

        margin-top: 20%;
    }
}
@media (max-width: 993px) {
    .animated-heading {
        font-size: 1px;
        animation: growingAnimation 1.5s ease-in-out forwards;
    }

    @keyframes growingAnimation {
        from {
            font-size: 0px;
            opacity: 0;
        }
        to {
            font-size: 1.5rem;
            opacity: 1;
        }
    }
}
@media (min-width: 993px) {
    .animated-heading {
        font-size: 1px;
        animation: growingAnimation 1.5s ease-in-out forwards;
    }

    @keyframes growingAnimation {
        from {
            font-size: 0rem;
            opacity: 0;
        }
        to {
            font-size: 2.9rem;
            opacity: 1;
        }
    }
}

#home-section{
    /* Full height */
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bgContainer{
    width: 100%;
    height: 100vh;
}
video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#videoContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    /*
    height: 100%;
    */
}
#pdfIcon{
    margin-top: 10%;
}

@media (min-width: 991px){
    #home-section{
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
    }
}
@media (max-width: 600px) {
    #counterPart{
        margin-top: 2rem;
        height: 10px;
    }
    .homeIcon img{
        font-size: 1.8rem!important;
    }
    .number{
        font-size: 1.5rem!important;
    }
    .caption{
        font-size: .8rem!important;

    }
    #counterMainDiv{
        margin-bottom: 30%;
        margin-top: 10px;
        display: flex;
        align-items: baseline;
    }
    .section-counter{
        height: 10rem!important;
    }
}
@media (max-width: 767px) {
    #boilersDisplay{
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;

    }
  
}
#boilerSection{
    color: white;
    background-color: black;
    margin-top: -66px;
    border-radius: 50px 50px 0px 0px;
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
    z-index: 1;
    

}
 @media (min-width: 768px) {
    #iconBrandCert{
        margin-bottom: 100px;
        position: absolute;
        bottom: 0;
        width: 50%;
        align-items: center;
        
    }
    #boilerSection{
        color: white;
        background-color: black;
        margin-top: -66px;
        border-radius: 50px 50px 0px 0px;
        padding-top: 5rem;
        padding-bottom: 5rem;
        position: relative;
        z-index: 1;
        height: 1090px;

    }
} 

#counterPart{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.section-counter{
    height: 20rem;
}
#homeAbout{
    font-size: 2rem;

}
#homeAbout p{
    font-width:lighter;
}
#homeSectionBtnContact{
    font-weight: 900;
    border: 1px solid   #343a40;
    box-shadow: 10px 10px 10px 10px #343a40;
    transition: 0.5s ease-in-out;
}
#homeSectionBtnContact:hover{
    box-shadow: 5px 5px 5px  #343a40;
}
.mapSection{
    padding: 50px;
}
#infoNumber span{
    color: white;

}
#mapParagraph{
    margin-bottom: -15%;
}
@media (min-width: 1265px){
    #mapParagraph{
        margin-bottom: -10%;
    }
}
@media (min-width: 991px) {
    .mapImg img{
        width: 60%!important;
    }
}
.special_link {
    display: flex;
    text-transform: capitalize;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none !important;
    color: #ffffff;
    font-weight: 900;
    font-size: .9rem; }
.special_link:before {
    position: absolute;
    z-index: -1;
    background: #629ad9;
    content: "";
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    left: 0;
    bottom: 0;
    top: 50%;
    width: 0; }
.special_link:hover {
    color: #ffffff; }
.special_link:hover:before {
    width: 100%; }
#solar-section{
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20%;
}
@media (min-width: 991px){
    #solar-section{
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
    }
}
#card-box{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
    width: 65%;
    height: 300px;
    background-color: #28a745;
}
@media (max-width: 769px) {
    #card-text{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
@media (max-width: 500px) {
    #card-box{
        flex-direction: column;
    }

}

/*ABOUT*/
/*GrassWorld*/
@media (max-width: 600px) {
    #grassId1{
        position: absolute;
    }
    #grassId2{
        margin-top: -130px;
    }
    #grassId3{
        margin-top: -50px;
    }
    #grassId4{
        margin-top: 302px;
    }

}
#aboutSecondpara{
    justify-content: flex-end;
}
#firstImgInAbout{
    height: 30rem!important;
}
#firstImgInAbout2{
   object-fit: cover;
    
}
.carouselSection{
    margin-top:-10rem;
    padding: 10%;
}
@media (max-width: 480px){
    .carouselSection{
        margin-top: 30%;
    }
}
.modal {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, 0.5); /* Semi-transparent background */
    justify-content: center;
    align-items: center;
    
  }
@media screen and (max-width: 480px){
    #twinImageAbout{
      margin-bottom: -60%;

    }
}
  .modal.open {
   display: flex;
   justify-content: center;
   align-items: center;
  }
  
  .modal-content {
    position: relative;
  }
  #modalContent{
    background-color: transparent !important;
    border: none !important;
  }
  
 #aboutButtonVideo{
    margin-top: 10px;
    border: none !important;
    border-radius: 4px;
    height: 3rem;
    width: 10rem;
    box-shadow: 2px 2px 2px 5px #343a40;
    transition: 0.5s ease-in-out;
    background-color: transparent !important;
    color: white;
 }
 #aboutButtonVideo:hover{
    box-shadow: 1px 1px 1px 2px #343a40;
    
 }

#paragraphEco{
    margin-top: 25%;
}

/*CONTACT*/
.contactCont{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}
@media screen and (max-width: 992px){
    .contactCont{
        display: block;
        width: 100%;

    }
}
@media screen and (min-width: 992px){
    .contactCont{
        display: flex;
    }
}
#addressSection{
    margin-top: 15%;
    line-height: 220%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#addressSection .openAddress{
    max-width: 63%;
}
@keyframes drawCheck {
    0% {
        width: 0;
        height: 0;
    }

    50% {
        width: 24px;
        height: 0;
    }
    100% {
        width: 24px;
        height: 48px;
    }
}
.check {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
    height: 48px;
    width: 24px;
    border-bottom: 14px solid #78b13f;
    border-right: 14px solid #78b13f;
    animation: drawCheck 0.9s ease;

}
.modalbody{
    display: flex;
    justify-content: center;
}

/*FOOTER*/
#scrollTopUnique{
    margin-top: 10px;
}
@media (max-width: 769px) {
    #scrollTopUnique{
        margin-top: 1px;
    }
}

#footerInfoSection{
    display: flex;
    justify-content: center;
    margin-top: 35px;
}
#footerContactSection{

}
.copyRight {
    bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
}
.copyright{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
@media (max-width: 500px) {
    .copyright{
        font-size: 0.7rem!important;
    }
    #footerImg{
        width: 30%!important;
    }

}
#infoNumber{
    margin-top: 10px;
}
#footersocial{
    display: flex;
    justify-content: center;
    padding-bottom: 10%;
    margin-top: 2%;
}
#insta{
    margin-right: 2%;
}
#linked{
    margin-left: 2%;
}
#productsTitleIstek{
    min-height: 872px;
   
}

/* Solar Energy System Calculation Styles */
#solarEnergySystemCalculation {
    padding: 4rem 2rem;
}

#solarEnergySystemCalculation h1 {
    margin-bottom: 2.5rem;
    font-weight: 600;
    text-align: center;
    font-size: 2.5rem;
}

#solarEnergySystemCalculation .uk-bg-white {
    background: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

#solarEnergySystemCalculation .uk-heading-line {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    color: #333;
    text-align: center;
}

#solarEnergySystemCalculation .uk-form-label {
    font-weight: 500;
    color: #555;
    font-size: 1rem;
    white-space: nowrap;
    margin: 0;
    flex: 1;
    text-align: left;
}

#solarEnergySystemCalculation .uk-form-controls {
    margin: 0;
    width: 50%;
    display: flex;
    justify-content: center;
}

#solarEnergySystemCalculation .uk-select,
#solarEnergySystemCalculation .uk-input {
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    background-color: #f8f9fa;
    color: #333;
    width: 100%;
}

#solarEnergySystemCalculation .uk-select:focus,
#solarEnergySystemCalculation .uk-input:focus {
    border-color: #ce1a22;
    box-shadow: 0 0 0 3px rgba(206, 26, 34, 0.1);
    outline: none;
}

#solarEnergySystemCalculation .uk-select:hover,
#solarEnergySystemCalculation .uk-input:hover {
    border-color: #ce1a22;
}

#solarEnergySystemCalculation .uk-button {
    background: #ffffff;
    color: #000;
    border: 2px solid #000;
    padding: 1rem 3rem;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1rem;
    width: auto;
    min-width: 200px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    box-shadow: none;
}

#solarEnergySystemCalculation .uk-button:hover {
    background: #ffffff;
    transform: scale(1.1);
    box-shadow: none;
}

#solarEnergySystemCalculation .uk-button:active {
    transform: translateY(0);
}

#solarEnergySystemCalculation .uk-margin {
    margin-bottom: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
}

#solarEnergySystemCalculation .uk-form-controls {
    margin: 0;
    width: 50%;
    display: flex;
    justify-content: center;
}

#solarEnergySystemCalculation input:disabled {
    background-color: #f8f9fa;
    border-color: #e0e0e0;
    color: #666;
    cursor: not-allowed;
}

#solarEnergySystemCalculation .uk-form-controls .uk-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

@media (max-width: 768px) {
    #solarEnergySystemCalculation {
        padding: 2rem 1rem;
    }
    
    #solarEnergySystemCalculation .uk-bg-white {
        padding: 1.5rem;
    }
    
    #solarEnergySystemCalculation h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }
    
    #solarEnergySystemCalculation .uk-heading-line {
        font-size: 1.5rem;
    }
    
    #solarEnergySystemCalculation .uk-margin {
        flex-direction: column;
        align-items: flex-start;
    }
    
    #solarEnergySystemCalculation .uk-form-label {
        margin-bottom: 0.5rem;
    }
    
    #solarEnergySystemCalculation .uk-form-controls {
        width: 100%;
    }
    #aboutHumanSection{
        margin-top:-70px!important;
    }
}

#solarEnergySystemCalculation .uk-form-horizontal {
    position: relative;
}

#solarEnergySystemCalculation .uk-button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

#solarEnergySystemCalculation .uk-button {
    background: #ffffff;
    color: #000;
    border: 2px solid #000;
    padding: 1rem 3rem;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1rem;
    width: auto;
    min-width: 200px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    box-shadow: none;
}

#solarEnergySystemCalculation .uk-button:hover {
    background: #ffffff;
    transform: scale(1.1);
    box-shadow: none;
}

@media (max-width: 768px) {
    #solarEnergySystemCalculation .uk-form-horizontal {
        padding-bottom: 60px;
    }
    
    #solarEnergySystemCalculation .uk-button {
        width: 80%;
        min-width: unset;
    }
}

#solarEnergySystemCalculation .uk-margin {
    margin-bottom: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
}

#solarEnergySystemCalculation .uk-margin.button-margin {
    justify-content: center;
    margin-top: 2rem;
}

#solarEnergySystemCalculation .uk-form-margin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

#solarEnergySystemCalculation .uk-button {
    background: #ffffff;
    color: #000;
    border: 2px solid #000;
    padding: 1rem 3rem;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1rem;
    width: auto;
    min-width: 200px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    box-shadow: none;
}

@media (max-width: 768px) {
    #solarEnergySystemCalculation .uk-form-margin {
        flex-direction: column;
        align-items: flex-start;
    }
    
    #solarEnergySystemCalculation .uk-margin.button-margin {
        margin-top: 1.5rem;
    }
    
    #solarEnergySystemCalculation .uk-button {
        width: 200px;
    }
}

#solarEnergySystemCalculation .uk-form-controls {
    margin: 0;
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

#solarEnergySystemCalculation .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

#solarEnergySystemCalculation .calculate-button {
    background: #ffffff;
    color: #000;
    border: 2px solid #000;
    padding: 1rem 3rem;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1rem;
    width: auto;
    min-width: 200px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    box-shadow: none;
}

#solarEnergySystemCalculation .calculate-button:hover {
    background: #ffffff;
    transform: scale(1.1);
    box-shadow: none;
}

@media (max-width: 768px) {
    #solarEnergySystemCalculation .calculate-button {
        width: 200px;
    }
}

/* Separate button controls from form controls */
#solarEnergySystemCalculation .uk-form-controls.button-controls {
    width: 100%;
    justify-content: center;
    margin: 2rem 0;
}

#solarEnergySystemCalculation .uk-form-controls.button-controls .uk-button {
    width: auto;
    min-width: 200px;
}

/* Override any inherited flex-end alignment */
#solarEnergySystemCalculation .uk-margin.button-margin {
    justify-content: center !important;
    width: 100% !important;
}

#solarEnergySystemCalculation .uk-margin.button-margin .uk-form-controls {
    width: auto !important;
    justify-content: center !important;
}
.image-hover {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
  
  .image-hover .main-image {
    display: block;
    transition: opacity 0.3s ease-in-out;
  }
  
  .image-hover .hover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .image-hover:hover .main-image {
    opacity: 0;
  }
  
  .image-hover:hover .hover-image {
    opacity: 1;
  }
  #iconBrandCert a{
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    #ourServiceSection {
        background-color: white;
        margin-top: -36px!important;
        border-radius: 50px 50px 0px 0px;
        padding-top: 5rem;
        padding-bottom: 5rem;
        position: relative;
        z-index: 1;
    }
    #ourServiceSection h6{
        width: 90%!important;
    }
    #firstBoilerSec{
        width: 100%!important;
    }
    #secondBoilerSec{
        width: 100%!important;
        margin-top: 15%;
    }
    #secondBoilerSec #secondBoylerFig{
        display: contents;
        align-items: center;
    }
    #secondBoilerSec figure{
        width: 70%!important;
       
    }
    #projectCountryCardsMobile{
        font-size: smaller;
    }
}  